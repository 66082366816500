// Migrated
<template lang="pug">
.d-inline-block.v-popper--theme-dropdown.popper-no-arrow.v-popper__popper--shown.v-popper__popper--show-to(
  v-click-outside="close",
  :class="{ 'position-relative': fullWidth }"
)
  DropdownToggle
    slot(name="trigger", v-bind="{ open, close, toggle, isOpen }")
  DropdownMenu(:full-width="fullWidth", :close-on-click="closeOnClick", :scroll-shadow="scrollShadow")
    slot(name="content", v-bind="{ open, close, toggle, isOpen }")
</template>

<script>
const STATUS = {
  open: 'opened',
  closed: 'closed'
}

/**
 * @see https://gist.github.com/plmrlnsnts/4587f5856608140609f27a29d77d052f
 */
export default defineNuxtComponent({
  props: {
    fullWidth: {
      type: Boolean,
      default: false
    },

    closeOnClick: {
      type: Boolean,
      default: false
    },

    scrollShadow: {
      type: Boolean,
      default: false
    },
  },

  emits: ['open', 'close', 'hide', 'show'],

  data () {
    return {
      status: STATUS.closed,
    }
  },

  computed: {
    isOpen () {
      return this.status === STATUS.open
    }
  },

  mounted () {
    document.addEventListener('keydown', this.onKeydown)
  },

  beforeUnmount () {
    document.removeEventListener('keydown', this.onKeydown)
  },

  methods: {
    onKeydown (event) {
      if (this.isClosed()) { return }

      if (event.key === 'Escape') {
        event.preventDefault()
        this.close()
      }
    },

    open () {
      this.status = STATUS.open
      this.$emit('open')
    },

    close () {
      if (this.status === STATUS.open) {
        this.status = STATUS.closed
        this.$emit('close')
      }
    },

    toggle () {
      if (this.isOpened()) {
        this.close()
      } else {
        this.open()
      }
    },

    hide () {
      this.close()
      this.$emit('hide')
    },

    show () {
      this.open()
      this.$emit('show')
    },

    isOpened () {
      return this.isOpen
    },

    isClosed () {
      return !this.isOpen
    }
  },
})
</script>

<style lang="scss">
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.v-popper__wrapper {
  .v-popper--theme-dropdown & {
    .v-popper__inner {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
</style>
